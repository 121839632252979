<template>
<div class="container container-column">
  <div class="">
    <h1>{{ msg }}</h1>
    <div class="content item">
      <p>
        For the poor souls that may or may-not be..winning.
      </p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #fbf800;
}
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

}
.container-column{
  display: flex;
  flex-direction: column;
}
.item {
  flex: 1 1 300px; /* Allows items to grow, shrink, and have a minimum width */
  margin: 10px;
}
img {
  max-width: 100%;
  height: auto;
}

</style>

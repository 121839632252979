<template>
  <div class="container container-column">
    <div class="item">
     <img alt="Vue logo" src="./assets/ryawinning.png">
      <HelloWorld msg="Are Ya Winning Son?"/>
    </div>
    <div class="item">

    </div>
  </div>

</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fdcdea;
  //margin-top: 60px;
  background-color: black;
  height: 100%;
}
a {
  color: #fbf800;
}
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

}
.container-column{
  flex-direction: column;
}
.item {
  flex: 1 1 300px; /* Allows items to grow, shrink, and have a minimum width */
  margin: 10px;
}

img {
  max-width: 100%;
  height: auto;
}


</style>
